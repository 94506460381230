import { useMutation } from '@tanstack/vue-query'
import type { IUpdateImportDocumentRequest } from '@workflow'

export const useApiImportDocumentsModify = (
  companyId: string,
  documentId: string,
) => {
  const updateDocument = useMutation({
    mutationFn: (body: IUpdateImportDocumentRequest) =>
      fetchAPI(`/companies/${companyId}/importDocuments/${documentId}`, {
        method: 'PUT',
        body,
      }),
  })

  const deleteDocument = useMutation({
    mutationFn: () =>
      fetchAPI(`/companies/${companyId}/importDocuments/${documentId}`, {
        method: 'DELETE',
      }),
  })

  return {
    updateDocument,
    deleteDocument,
  }
}
